<template>
  <SubNavbar />
  <div id="show-video" ref="videoscreen">
    <div class="video">
      <VideoPlayer
        v-if="!loading"
        :videoId="game.videoId"
        :startAt="startAt"
        @timeUpdate="setCurrentTime"
      ></VideoPlayer>
      <div class="moment-buttons">
        <MomentButton
          v-for="category in categories"
          :key="category.id"
          :category="category"
          @clicked="addMoment" />
      </div>
    </div>
    <div class="moments">
      <div class="panel">
        <div class="panel-heading">
          Moments
          <a @click="fullscreenVideo" class="is-pulled-right">
            <span class="material-icons md-dark">
              fullscreen
            </span>
          </a>
        </div>
        <div class="panel-block">
          <p class="control has-icons-left">
            <input class="input" type="text" placeholder="Search">
            <span class="icon is-left">
              <span class="material-icons md-dark">search</span>
            </span>
          </p>
        </div>
        <p class="panel-tabs">
          <a>
            <span
              @click="showCategory = 'all'"
              class="icon all"
              :class="{ 'is-active': showCategory == 'all' }"
              title="all moments">
              <img src="/assets/icons/hockey/all.svg" />
            </span>
          </a>
          <a v-for="category in ['goal', 'shot', 'breakout', 'faceoff']" :key="category">
            <CategoryIcon
              :class="{ 'is-active': showCategory == category }"
              @click="showCategory = category"
              :category="category" />
          </a>
        </p>
      </div>
      <div v-if="game" class="moment-list">
        <MomentItem
          v-for="moment in filteredMoments"
          :key="moment.id"
          :moment="moment"
        >
      </MomentItem>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VideoPlayer from '../components/VideoPlayer.vue';
import SubNavbar from '../components/SubNavbar.vue';
import MomentItem from '../components/MomentItem.vue';
import CategoryIcon from '../components/CategoryIcon.vue';
import MomentButton from '../components/MomentButton.vue';

export default {
  name: 'ShowGame',
  title() { return `Mylights - ${this.videoId}`; },
  components: {
    VideoPlayer,
    SubNavbar,
    MomentItem,
    CategoryIcon,
    MomentButton,
  },
  props: {
    gameId: {
      type: String,
      required: true,
    },
    momentId: {
      type: String,
    },
  },
  data() {
    return {
      startAt: null,
      currentTime: 0.1,
      loading: true,
      showFSButton: false,
      showCategory: 'all',
    };
  },
  computed: {
    ...mapState([
      'categories',
      'moments',
    ]),
    filteredMoments() {
      if (!this.moments) return [];

      const moments = [...this.moments].sort((a, b) => a.startsAt - b.startsAt);
      if (this.showCategory === 'all') return moments;

      return moments.filter((moment) => moment.category === this.showCategory);
    },
    game() {
      return this.$store.state.games.find((game) => game.id === this.gameId);
    },
  },
  methods: {
    ...mapActions([
      'setGameId',
      'setCurrentTime',
      'addMoment',
      'deleteMoment',
      'loadMoments',
      'saveMylights',
    ]),
    fullscreenVideo() {
      this.$refs.videoscreen.requestFullscreen();
    },
  },
  async mounted() {
    this.loading = true;
    await this.setGameId(this.gameId);
    await this.loadMoments();
    if (this.momentId) {
      this.startAt = this.game.moments[this.momentId].startsAt;
    }

    this.loading = false;
  },
  created() {
    if (document.fullscreenEnabled) this.showFSButton = true;
  },
};
</script>

<style lang="scss" scoped>
#show-video {
  display: flex;
  height: 100vh;

  position: absolute;
  top: 104px;
  left: 0;
  right: 0;
  bottom: 0;

    .video {
      flex: auto;
      padding: 20px;
      background-color: #000;
      height: 100%;
  }

  .moment-buttons {
    padding: 4px;
    background-color: #333;
  }

  .moments {
    flex: 0 300px;
    padding: 0;
    color: #eee;
    overflow: auto;
    min-height: 0;
  }

  .icon.all {
    img {
      padding: 2px;
    }
    width: 24px;
    height: 24px;
  }

  .panel .icon {
    padding: 2px;
    &.is-active {
      background-color: #ccc;
    }

}
}
</style>
