<template>
  <nav class="navbar">
    <div class="navbar-brand">
      <a class="navbar-item" @click="hasHistory() ? $router.go(-1) : $router.push('/')">
        <img
          src="../assets/left-chevron.svg"
          class="image is-24x24" />
        Back to Videos
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>
