import { createStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import ls from '../api/localStorageService';
import idbs from '../api/indexedDBService';

const store = createStore({
  state: {
    currentTime: 0.01,
    gameId: null,
    games: [
      {
        id: 'a1',
        videoId: 'ax9Q9quFAhA',
        title: '@ Potomac Lady Patriots',
        startedAt: new Date('2021-09-19T17:30:00'),
        location: 'Prince William Ice Arena',
        source: 'YOUTUBE',
        createdAt: Date.now(),
        updatedAt: Date.now(),
      },
    ],
    moments: [],
    // eslint-disable-next-line no-use-before-define
    categories: [
      {
        id: 'goal',
        code: 'goal',
        label: 'Goal',
        hotkey: 'g',
        offset: -5,
        duration: 5,
      },
      {
        id: 'shot',
        code: 'shot',
        label: 'Shot',
        hotkey: 's',
        offset: -3,
        duration: 5,
      },
      {
        id: 'faceoff',
        code: 'faceoff',
        label: 'Faceoff',
        hotkey: 'f',
        offset: -2,
        duration: 5,
      },
      {
        id: 'breakout',
        code: 'breakout',
        label: 'Breakout',
        hotkey: 'b',
        offset: -3,
        duration: 5,
      },
    ],
    dataFields: ['moments'],
  },
  mutations: {
    setState(state, { field, data }) {
      state[field] = data;
    },
    setCurrentTime(state, newTime) {
      state.currentTime = newTime;
    },
    setGameId(state, gameId) {
      state.gameId = gameId;
    },
    addGame(state, newGame) {
      state.games.push(newGame);
    },
    deleteGame(state, { gameIndex, isCompleted }) {
      if (isCompleted) {
        state.completed.splice(gameIndex, 1);
      } else {
        state.games.splice(gameIndex, 1);
      }
    },
    addMoment(state, newMoment) {
      state.moments.push(newMoment);
    },
    deleteMoment(state, { momentIndex, isCompleted }) {
      if (isCompleted) {
        state.completed.splice(momentIndex, 1);
      } else {
        state.moments.splice(momentIndex, 1);
      }
    },
  },
  actions: {
    setCurrentTime({ commit }, newTime) {
      commit('setCurrentTime', newTime);
    },
    setGameId({ commit }, newGameId) {
      commit('setGameId', newGameId);
    },
    async addGame({ commit, dispatch }, newGame) {
      const id = uuidv4();
      const game = { ...newGame, id };
      commit('addGame', game);
      dispatch('saveGames');
      return id;
    },
    deleteGame({ commit, dispatch }, gameInfo) {
      commit('deleteGame', gameInfo);
      dispatch('saveGames');
    },
    async addMoment({ state, commit, dispatch }, newMoment) {
      const adjustedStartTime = state.currentTime + newMoment.category.offset;
      const startsAt = adjustedStartTime >= 0 ? adjustedStartTime : 0.1;
      const endsAt = startsAt + newMoment.category.duration;
      const id = uuidv4();

      const moment = {
        id,
        category: newMoment.category.id,
        startsAt,
        endsAt,
        gameId: state.gameId,
      };

      commit('addMoment', moment);
      dispatch('saveMylights');
    },
    deleteMoment({ commit, dispatch }, momentInfo) {
      commit('deleteMoment', momentInfo);
      dispatch('saveMylights');
    },

    async loadItems({ state, commit }) {
      state.dataFields.forEach(async (field) => {
        try {
          let data = await idbs.getAllItems(field);
          if (data === null) data = [];

          commit('setState', { field, data });
        } catch (e) {
          commit('setState', { field, data: [] });
        }
      });
    },

    async loadMoments({ state, commit }) {
      if (!state.gameId) throw new Error('Game ID not set');

      try {
        let data = JSON.parse(await idbs.getItem('moments', state.gameId));
        data = data || [];
        commit('setState', { field: 'moments', data });
      } catch (error) {
        console.error(error);
        return error;
      }
    },

    checkStorage({ state, commit }) {
      state.dataFields.forEach(async (field) => {
        try {
          let data = JSON.parse(await idbs.checkStorage(field));
          // if (data === undefined) data = ls.checkStorage(field);
          if (data === null) data = [];

          commit('setState', { field, data });
        } catch (e) {
          commit('setState', { field, data: [] });
        }
      });
    },
    async saveMylights({ state }) {
      try {
        console.log('SAVING!!!', state.gameId);
        await Promise.all(
          state.dataFields.map(
            (field) => idbs.saveToStorage(field, state.gameId, state[field]),
          ),
        );
      } catch (e) {
        state.dataFields.forEach((field) => ls.saveToStorage(field, state[field]));
      }
    },
  },
  getters: {
    getGameById: (state) => (gameId) => state.games.find((game) => game.id === gameId),
  },
});

// const categories = [
//   {
//     id: 'goal',
//     code: 'goal',
//     label: 'Goal',
//     hotkey: 'g',
//     offset: -5,
//     duration: 5,
//   },
//   {
//     id: 'shot',
//     code: 'shot',
//     label: 'Shot',
//     hotkey: 's',
//     offset: -3,
//     duration: 5,
//   },
//   {
//     id: 'faceoff',
//     code: 'faceoff',
//     label: 'Faceoff',
//     hotkey: 'f',
//     offset: -2,
//     duration: 5,
//   },
//   {
//     id: 'breakout',
//     code: 'breakout',
//     label: 'Breakout',
//     hotkey: 'b',
//     offset: -3,
//     duration: 5,
//   },
// ];

export default store;
