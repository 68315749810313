<template>
  <button @click="clicked"><CategoryIcon :category="category.code" /></button>
</template>

<script>
import CategoryIcon from './CategoryIcon.vue';

export default {
  components: {
    CategoryIcon,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clicked() {
      this.$emit('clicked', { category: this.category });
    },
    hotkeyListener(event) {
      if (!this.category.hotkey) return;
      if (this.category.hotkey === event.key) this.clicked();
      if (this.category.hotkey.toUpperCase() === event.key) this.clicked('them');
    },
  },
  created() {
    window.addEventListener('keydown', this.hotkeyListener);
  },
  unmounted() {
    window.removeEventListener('keydown', this.hotkeyListener);
  },
};
</script>

<style lang="scss">
.hotkey {
  font-weight: bold;
}

button {
  margin-right: 0.5rem;
}
</style>
