import localforage from 'localforage';

const store = (storeName) => {
  const db = localforage.createInstance({
    name: 'mylights',
    storeName,
  });
  return db;
};

const getItem = async (storeName, key) => {
  try {
    const db = await store(storeName);
    const result = await db.getItem(key);
    return result;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const setItem = async (storeName, key, value) => {
  try {
    const db = await store(storeName);
    const result = await db.setItem(key, value);
    return result;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const removeItem = async (storeName, key) => {
  try {
    const db = await store(storeName);
    const result = await db.remove(key);
    return result;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const checkStorage = async (storeName) => {
  try {
    const db = await store(storeName);
    const result = await db.getItem(storeName);
    return result;
  } catch (error) {
    return error;
  }
};

const saveToStorage = async (storeName, key, value) => {
  try {
    const data = JSON.stringify(value);
    const db = await store(storeName);
    const result = db.setItem(key, data);
    return result;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  getItem,
  setItem,
  removeItem,
  checkStorage,
  saveToStorage,
};
