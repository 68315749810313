<template>
  <div class="video-wrapper">
    <video
      ref="videoPlayer"
      class="video-js">
    </video>
  </div>
</template>

<script>
import videojs from 'video.js';
import eventBus from '../eventBus';
import 'videojs-youtube';
import { isValidYoutubeVideoId, sanitizedYoutubeVideoId } from '../libs/video';

export default {
  name: 'VueYoutubePlayer',
  props: {
    videoId: {
      type: String,
      required: true,
      validator: (videoId) => isValidYoutubeVideoId(videoId),
    },
    startAt: {
      type: Number,
      default: 1,
    },
  },
  emits: [
    'timeUpdate',
  ],
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options);
    this.player.on('timeupdate', () => { this.$emit('timeUpdate', this.player.currentTime()); });
    this.player.ready(() => {
      this.player.play();
      eventBus.$on('seekTo', this.seekTo);
    });
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
  computed: {
    sanitizedVideoId() { return sanitizedYoutubeVideoId(this.videoId); },
    options() {
      return {
        techOrder: ['youtube'],
        sources: [
          {
            type: 'video/youtube',
            src: `//www.youtube.com/watch?v=${this.sanitizedVideoId}&t=${Number(this.startAt)}`,
          },
        ],
        controls: true,
        playbackRates: [0.5, 1, 1.5, 2],
        aspectRatio: '16:9',
        youtube: {
          start: this.startAt,
        },
        controlBar: {
          fullscreenToggle: false,
        },
      };
    },
  },
  methods: {
    seekTo(seconds) {
      this.player.currentTime(seconds);
    },
    keyupListener(event) {
      console.log(event);
    },
  },
  watch: {
    videoid() {
      this.player.loadVideoById(this.videoid);
      this.player.play();
    },
  },
};
</script>

<style src="video.js/dist/video-js.min.css" />
<style lang="scss">
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;

  .video-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
