<template>
  <div
    class="panel-block is-clickable moment-item"
    :class="{ 'is-active': isActive }"
    @click="seekTo"
  >
    <CategoryIcon class="category-icon" :category="moment.category" />
    <span class="starts-at has-text-weight-bold">{{ startsAt }}</span>
    <span class="has-text-weight-normal is-size-7">
      {{ description }}
    </span>
  </div>
</template>

<script>
import eventBus from '../eventBus';
import CategoryIcon from './CategoryIcon.vue';

export default {
  components: {
    CategoryIcon,
  },
  props: [
    'moment',
    'currentTime',
  ],
  methods: {
    seekTo() {
      eventBus.$emit('seekTo', this.moment.startsAt);
    },
  },
  computed: {
    startsAt() {
      if (!this.moment) return null;
      return new Date(Number(this.moment.startsAt) * 1000).toISOString().substr(12, 7);
    },
    description() {
      return this.moment.description ? this.moment.description : '---';
    },
    isActive() {
      const { startsAt, endsAt } = this.moment;
      const { currentTime } = this;
      return currentTime > Number(startsAt) && currentTime <= endsAt;
    },
    teamClass() {
      if (!this.moment || !this.moment.team) return 'is-dark';
      return this.moment.team === 'them' ? 'is-light' : 'is-dark';
    },
  },
};
</script>

<style lang="scss">
.starts-at {
  margin-right: 1rem;
}
.category-icon {
  margin-right: 0.5rem;
}
.is-active {
  background-color: #e9f9eb;
}

.moment-item {
  .category-icon img {
    height: 18px;
    height: 18px;
  }
}
</style>
