<template>
  <div class="container games">
    <div class="buttons">
      <button class="button is-primary" @click="this.showForm=true;">Add Game</button>
    </div>
    <div class="columns is-multiline">
      <div
        class="column is-one-third"
        v-for="game in games"
        :key="game.id"
      >
        <GameCard
          :game="game"
          :photoUrl="photoUrl"/>
      </div>
    </div>
    <GameForm :class="{ 'is-active': showForm }" @closeForm="this.showForm = false" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GameCard from '@/components/GameCard.vue';
import GameForm from '@/components/GameForm.vue';

export default {
  components: {
    GameCard,
    GameForm,
  },
  data() {
    return {
      showForm: false,
    };
  },
  computed: {
    ...mapState([
      'games',
    ]),
  },
  mounted() {
  },
};
</script>

<style lang="scss">
.games {
 padding-top: 40px;
}
</style>
