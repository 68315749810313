<template>
<router-link :to="`/games/${game.id}`">
  <div class="game-card card">
    <div class="card-image">
      <figure class="image is-16by9">
        <img :src="thumbnailUrl" alt="Placeholder image">
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-4">{{ game.title }}</p>
          <p class="subtitle is-6">{{ gameTime }}</p>
          <p class="subtitle is-6">{{ game.location }}</p>
        </div>
      </div>
    </div>
  </div>
</router-link>
</template>

<script>
import { shortDateTime } from '../libs/timeFormats';

export default {
  name: 'GameCard',
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  computed: {
    thumbnailUrl() {
      return `https://img.youtube.com/vi/${this.game.videoId}/0.jpg`;
    },
    gameTime() {
      if (!this.game.startedAt) return '';
      return shortDateTime(this.game.startedAt);
    },
  },
};
</script>
