<template>
  <div class="mylights">
    <router-view/>
  </div>
</template>

<script>
// import NavBar from '@/components/NavBar.vue';

export default {
  components: {
    // NavBar,
  },
};
</script>

<style lang="scss">
@import "bulma/sass/utilities/_all";
@import "bulma/sass/base/_all";
@import "bulma/sass/elements/_all";
@import "bulma/sass/form/_all";
@import "bulma/sass/components/_all";
@import "bulma/sass/grid/_all";
@import "bulma/sass/helpers/_all";
@import "bulma/sass/layout/_all";

$mdi-font-path: "~@mdi/font/fonts";
@import "~@mdi/font/scss/materialdesignicons";

body {
  margin: 0;
  padding: 0px;
}
</style>
