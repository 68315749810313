<template>
<div class="modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Add Game Video</p>
      <button @click="closeForm" class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <label class="label">Youtube URL</label>
        <div class="control">
          https://www.youtube.com/watch?v=<input
            v-model="videoId"
            class="input"
            type="text"
            placeholder="{video id}">
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-success"
        :disabled="!isValidVideoId"
        @click="saveGame"
      >
        Save changes
      </button>
      <button @click="closeForm" class="button">Cancel</button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import { isValidYoutubeVideoId } from '../libs/video';

export default {
  data() {
    return {
      videoId: null,
    };
  },
  computed: {
    isValidVideoId() {
      return isValidYoutubeVideoId(this.videoId);
    },
  },
  methods: {
    ...mapActions([
      'addGame',
    ]),
    closeForm() {
      this.$emit('closeForm');
    },
    async saveGame() {
      if (!this.isValidVideoId) return;
      const gameId = await this.addGame({ videoId: this.videoId });
      this.$router.push(`/games/${gameId}`);
      this.closeForm();
    },
  },
};
</script>
