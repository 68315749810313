import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import ShowGames from '../views/GameShow.vue';
import ListGames from '../views/GameIndex.vue';

const routes = [
  {
    path: '/error',
    name: 'ErrorPage',
    component: () => import(/* webpackChunkName: "error" */ '../views/ErrorPage.vue'),
  },
  {
    path: '/games',
    name: 'GameIndex',
    component: ListGames,
  },
  {
    path: '/games/:id',
    name: 'GameShow',
    component: ShowGames,
    props: (route) => ({ gameId: route.params.id, momentId: route.query.momentId }),
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/error',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
