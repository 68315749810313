<template>
  <span class="icon" :title="category">
    <img :src="iconPath" />
  </span>
</template>

<script>
const categories = [
  'goal',
  'breakout',
  'faceoff',
  'shot',
];

export default {
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconPath() {
      if (categories.includes(this.category)) return `/assets/icons/hockey/${this.category}.svg`;
      return '';
    },
  },
};
</script>
